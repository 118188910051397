<template>
  <div class="notice">
    <table-list
      class="tableList"
      :title="tableTitle"
      :loading="loading"
      :search-form="searchForm"
      :data="noticeList"
      :options="{ selection: true, operatesWidth: 100, fixScrollTable: true }"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>

    <el-dialog
      :title="status == 1 ? '发布公告' : '编辑公告'"
      :visible.sync="dialogVisibleBinding"
      width="50%"
      :close-on-click-modal="false"
      class="Item_form"
      @close="closeDialog"
    >
      <div class="Item_notice">
        <span></span>
      </div>
      <el-form ref="formBinding" :model="formBinding" label-width="120px" :rules="rules">
        <el-form-item label="公告主题：" prop="title">
          <el-input
            v-model="formBinding.title"
            placeholder="请输入4~24字主题"
            class="input-width"
            maxlength="64"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="公告内容：" prop="content">
          <el-input
            v-model="formBinding.content"
            placeholder="请输入6~251字内容"
            type="textarea"
            class="input-width"
            :rows="8"
            clearable
          ></el-input>
          <!-- <tinymce ref="editor" v-model="formBinding.content" @onClick="onClick"></tinymce> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel('formBinding')">取消</el-button>
        <el-button type="primary" @click="handleNoticeSubmit('formBinding')">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="公告详情"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      class="noticeDetail"
    >
      <el-form ref="formDetail" :model="formDetail" label-width="120px">
        <el-form-item label="公告主题：" prop="title">
          <div>{{ formDetail.title }}</div>
        </el-form-item>
        <el-form-item label="公告内容：" prop="content" class="content">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="formDetail.content"></div>
        </el-form-item>
        <el-form-item label="发布人：" prop="userName">
          <div>{{ formDetail.userName }}</div>
        </el-form-item>
        <el-form-item label="发布时间：" prop="updateTime">
          <div>{{ formDetail.updateTime }}</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">我知道了</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//表格标题
const tableTitle = '书院公告'
// 顶部按钮
const btns = _this => [
  {
    label: '发布公告',
    method: _this.handleRelease,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = [
  {
    label: '主题/内容',
    prop: 'keyword',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'title',
    label: '公告主题',
    minWidth: '200px',
    render(h, { row }) {
      return h(
        'div',
        {
          class: 'tableRowTitle',
        },
        row.title,
      )
    },
  },
  {
    prop: 'content',
    label: '公告内容',
    minWidth: '300px',
    render(h, { row }) {
      return h(
        'div',
        {
          class: 'tableLine',
          domProps: {
            innerHTML: row.content,
          },
        },
        // row.content,
      )
    },
  },
  {
    prop: 'updateTime',
    label: '发布时间',
    minWidth: '150px',
  },
  {
    prop: 'pageViews',
    label: '浏览次数',
    align: 'right',
    minWidth: '100px',
  },
  {
    prop: 'userName',
    label: '发布人',
    minWidth: '80px',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
  {
    label: '详情',
    method: _this.handleDetail,
  },
]
const listObj = {
  title: '',
  content: '',
}
import TableList from '@/components/TableList'
// import Tinymce from '@/components/Tinymce'
import {
  getCollegeNoticeList,
  getCollegeNoticeListDel,
  getCollegeNoticeListEdit,
  getCollegeNoticeListAdd,
  getCollegeNoticeViews,
} from '@/api/college'
import to from 'await-to'
export default {
  components: {
    TableList,
    // Tinymce,
  },
  data() {
    return {
      tableTitle,
      searchForm,
      columns,
      operates,
      btns,
      loading: false,
      noticeList: [],
      multipleVal: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      formBinding: {
        title: '',
        content: '',
        keyword: '',
      },
      rules: {
        title: [
          { required: true, message: '请输入4-24字标题', trigger: 'blur' },
          { min: 4, max: 24, message: '请输入4-24字标题', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入6-251字内容', trigger: 'blur' },
          { min: 6, max: 251, message: '请输入6-251字内容', trigger: 'blur' },
        ],
      },
      status: 0,
      dialogVisibleBinding: false,
      dialogVisible: false,
      formDetail: {
        title: '',
        content: '',
        userName: '',
        updateTime: '',
      },
    }
  },
  created() {
    this.getCollegeNoticeListData()
  },
  methods: {
    async getCollegeNoticeListData() {
      this.loading = true
      const [res, err] = await to(getCollegeNoticeList(this.pager))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.noticeList = res.data.list
      this.pager.total = res.data.total
    },
    onClick() {},
    // 查询按钮
    onSearch(params) {
      this.pager.current = 1
      this.pager.keyword = params.keyword
      this.getCollegeNoticeListData()
    },
    // 编辑
    handleEdit(row) {
      //   console.log(row)
      this.status = 2
      this.dialogVisibleBinding = true
      this.formBinding = Object.assign({}, row)
    },
    // 删除
    async handleDelete() {
      if (!this.multipleVal.length) return this.$message.warning('请先选择要删除的公告')
      let select = []
      this.multipleVal.map(v => {
        select.push(v.id)
      })
      this.$confirm('你确认要删除吗?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const [, err] = await to(getCollegeNoticeListDel({ ids: select }))
          if (err) return this.$message({ type: 'error', message: err.msg })
          this.$message({ type: 'success', message: '删除成功' })
          this.getCollegeNoticeListData()
        })
        .catch(() => {})
    },
    //多选
    handleSelectionChange(val) {
      this.multipleVal = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCollegeNoticeListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCollegeNoticeListData()
    },
    //添加
    handleRelease() {
      this.status = 1
      this.dialogVisibleBinding = true
    },
    //详情
    async handleDetail(row) {
      this.dialogVisible = true
      this.formDetail = Object.assign({}, row)
      const [, err] = await to(getCollegeNoticeViews({ id: row.id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.getCollegeNoticeListData()
    },
    success(formName, message) {
      this.$message({ type: 'success', message: message })
      this.$refs[formName].resetFields()
      this.dialogVisibleBinding = false
      this.getCollegeNoticeListData()
    },
    //提交
    async handleNoticeSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.status == 1) {
            const [, err] = await to(getCollegeNoticeListAdd(this.formBinding))
            if (err) return this.$message({ type: 'error', message: err.msg })
            this.success(formName, '发布成功')
          } else {
            const [, err] = await to(getCollegeNoticeListEdit(this.formBinding))
            if (err) return this.$message({ type: 'error', message: err.msg })
            this.success(formName, '编辑成功')
          }
        }
      })
    },
    cancel() {
      this.dialogVisibleBinding = false
      this.closeDialog()
    },
    closeDialog() {
      if (this.status == 2) {
        this.formBinding = listObj
      }
    },
  },
}
</script>

<style scoped lang="scss">
.noticeDetail ::v-deep .el-dialog__footer {
  text-align: center !important;
}
.tableList ::v-deep .el-table tr .tableLine,
.tableList ::v-deep .el-table tr .tableRowTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; //控制行数
  overflow: hidden;
}
.Item_form ::v-deep .el-dialog__header {
  border-bottom: 1px solid #e2e2e2;
}
.Item_form ::v-deep .el-dialog__footer {
  border-top: 1px solid #e2e2e2;
  padding-top: 20px;
}
</style>
